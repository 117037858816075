import { ref, onMounted, onBeforeUnmount, onActivated, onDeactivated } from 'vue'
import { useScrollStatus } from '~/store/scrollStatus'
import debounce from 'lodash/debounce'

let log = console.log
log = () => {}

/**
 * @description: 頁面滾動的檢測函數
 * @param seletor 滾動容器的CSS選擇器，不傳則使用 window 做為滾動檢測容器
 * @param sensitivity 頁面是否滾動的檢測時間靈敏度，默認 500 ms
 * @param step 節流函數的 默認 50 ms
 */
export const useScrollEvent = (seletor?: string, step: number = 10) => {
  const el: Ref<HTMLElement | Window | typeof globalThis | null> = ref(window)

  const timer: any = ref(null)

  const derection: Ref<'Up' | 'Down' | null> = ref(null)

  const lastPosition: Ref<number> = ref(0)

  const { setIsScrolling, setScrollDirection } = useScrollStatus()

  // 是否正在滾動
  const isScrolling: Ref<Boolean> = ref(false)

  function handleScroll() {
    detectUpAndDown()
    detectIsScrolling()
  }

  function detectUpAndDown() {
    const currentPosition = (el.value as HTMLElement)?.scrollTop || (el.value as Window)?.scrollY || 0

    if (lastPosition.value < currentPosition) {
      log('向下滚动')
      // emit('scrollEmitter', true)
      derection.value = 'Down'
      setScrollDirection('Down')
    } else if (lastPosition.value > currentPosition) {
      log('向上滚动')
      derection.value = 'Up'
      setScrollDirection('Up')
    }
    lastPosition.value = currentPosition
  }

  function detectIsScrolling() {
    setIsScrolling(true)
    clearTimeout(timer.value)
    if (!isScrolling.value) {
      isScrolling.value = true
      setIsScrolling(true)
      log('isScrolling:', isScrolling.value)
    }
    timer.value = setTimeout(() => {
      isScrolling.value = false
      setIsScrolling(false)
      log('isScrolling:', isScrolling.value)
    }, 1000)
  }

  // 節流處理
  const debounceScroll = debounce(handleScroll, step, {
    leading: true,
  })

  const addEvent = () => {
    const t = setTimeout(() => {
      clearTimeout(t)
      el.value = (document.querySelector(`${seletor}`) as HTMLElement) ?? window
      el.value?.addEventListener('scroll', debounceScroll)
    }, 500)
  }
  const removeEvent = () => {
    el.value?.removeEventListener('scroll', debounceScroll)
  }

  onMounted(() => {
    addEvent()
  })

  onBeforeUnmount(() => {
    removeEvent()
  })

  onActivated(() => {
    addEvent()
  })

  onDeactivated(() => {
    removeEvent()
  })

  return { derection, lastPosition, isScrolling }
}
